<ng-container>
    <div class="dynamic__padding--lg">
        <div class="cms__view">
            <app-cms-sidebar
                #cmsSidebar
                class="cms__sidebar"
                (groupChanged)="onGroupChanged($event)"
                (categoryChanged)="onCategoryChanged($event)">
            </app-cms-sidebar>

            <ng-container *ngIf="!!selectedCategory">
                <app-cms-edit class="cms__edit"
                              [category]="selectedCategory"
                              (categoryDeleted)="onCategoryDeleted();"></app-cms-edit>
            </ng-container>
        </div>
    </div>
</ng-container>
