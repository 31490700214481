import { Component, ViewChild } from '@angular/core';
import { Group } from '../../lib/Group';
import { Category } from '../../lib/Category';
import { CmsEditComponent } from './components/cms-edit/cms-edit.component';
import { CommonModule } from '@angular/common';
import { CmsSidebarComponent } from './components/cms-sidebar/cms-sidebar.component';

@Component({
    standalone: true,
    selector: 'app-cms',
    templateUrl: './cms.component.html',
    styleUrls: ['./cms.component.scss'],
    imports: [CommonModule, CmsSidebarComponent, CmsEditComponent],
})
export class CmsComponent {
    @ViewChild('cmsSidebar') cmsSidebar?: CmsSidebarComponent;

    selectedGroup?: Group;
    selectedCategory?: Category;

    onGroupChanged(group: Group): void {
        this.selectedGroup = group;
    }

    onCategoryChanged(category: Category): void {
        this.selectedCategory = category;
    }

    onCategoryDeleted(): void {
        this.onCategoryChanged(undefined);
        this.cmsSidebar.fetchCategories();
    }
}
