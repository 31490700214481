<div class="cms-edit__view">
    <app-cms-edit-toolbar class="cms-edit__toolbar" (editToolbarToolClicked)="onEditToolbarToolClicked($event)">
    </app-cms-edit-toolbar>
    <ng-container *ngIf="commonService.IsPlatformBrowser() && source?.length">
        <gui-grid
                class="cms-edit__grid"
                [source]="source"
                [cellEditing]="true"
                [rowHeight]="100"
                (sourceEdited)="onSourceEdited($event.before, $event.after)">
            <gui-grid-column header="Bezeichnung" field="tag">
                <ng-template let-value="value">
                    {{ value }}
                </ng-template>
            </gui-grid-column>
            <gui-grid-column header="Text" field="textContent" [cellEditing]="false">
                <ng-template let-value="value" let-index="index">
                    <textarea class="cms-edit__grid__column__textarea dynamic__text--xs"
                              [(ngModel)]="source[index].textContent"
                              (focusout)="onTextChange(index, value)">{{ value }}</textarea>
                </ng-template>
            </gui-grid-column>
            <gui-grid-column
                    *ngIf="isGroupHavingImages()"
                    header="Bildvorschau"
                    field="imageContent"
                    [cellEditing]="false">
                <ng-template let-value="value" let-index="index">
                    <div class="cms-edit__grid__column--image-preview__image dynamic__position--relative">
                        <img class="cms-edit__grid__column--image-preview__image"
                             [class.cms-edit__grid__column--image-preview__image--error]="this.source[index].imageError"
                             alt="preview"
                             src="{{value}}"
                             (load)="this.source[index].imageLoading = false; this.source[index].imageError = false;"
                             (error)="this.source[index].imageError = true;">
                        <div *ngIf="source[index].imageLoading"
                             class="cms-edit__grid__column--image-preview__image--loading"></div>
                    </div>
                </ng-template>
            </gui-grid-column>
            <gui-grid-column *ngIf="isGroupHavingImages()" header="Aktionen" field="id" [cellEditing]="false">
                <ng-template let-value="value" let-i="index">
                    <div class="cms-edit__grid__column--actions">
                        <button class="cms-edit__grid__column--actions__button--change-image">
                            <label [for]="'image-change-' + i">
                                <span class="dynamic__element--pointer">Bild ändern</span>
                            </label>
                        </button>
                        <input class="cms-edit__grid__column--actions__input--change-image"
                               type="file"
                               accept="image/*"
                               [id]="'image-change-' + i"
                               (change)="onImageChangeButtonClicked(value, i, $event)"/>

                        <button class="cms-edit__grid__column--actions__button--change-image"
                                (click)="clearRow(i)">
                            Leeren
                        </button>
                    </div>
                </ng-template>
            </gui-grid-column>
        </gui-grid>
    </ng-container>
</div>
