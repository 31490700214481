import { Component, EventEmitter, inject, OnDestroy, Output } from '@angular/core';
import { CategoryGroupService } from '../../../../services/category-group/category-group.service';
import { Group } from '../../../../lib/Group';
import { DynamoImageType, DynamoListMenuIconLibrary, DynamoListMenuItem, DynamoModule } from '@skillgmbh/dynamo';
import { Category } from '../../../../lib/Category';
import { UserService } from '../../../../services/user/user.service';
import { CategoryService } from '../../../../services/category/category.service';
import { CommonModule } from '@angular/common';
import { CommonService } from '../../../../services/common/common.service';
import { Subject, take, takeUntil } from 'rxjs';

@Component({
    standalone: true,
    selector: 'app-cms-sidebar',
    templateUrl: './cms-sidebar.component.html',
    styleUrls: ['./cms-sidebar.component.scss'],
    imports: [CommonModule, DynamoModule],
})
export class CmsSidebarComponent implements OnDestroy {
    readonly DynamoImageType: typeof DynamoImageType = DynamoImageType;

    @Output() groupChanged: EventEmitter<Group> = new EventEmitter<Group>();
    @Output() categoryChanged: EventEmitter<Category> = new EventEmitter<Category>();

    protected groups?: Group[];
    protected groupListMenuOptions: DynamoListMenuItem[] = [];
    protected selectedGroup?: Group;
    protected categories?: Category[];
    protected categoryListMenuOptions: DynamoListMenuItem[] = [];
    private destroyed$: Subject<void> = new Subject<void>();

    protected userService: UserService = inject(UserService);
    private categoryGroupService: CategoryGroupService = inject(CategoryGroupService);
    private categoryService: CategoryService = inject(CategoryService);
    private commonService: CommonService = inject(CommonService);

    constructor() {
        this.fetchGroups();
    }

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    fetchGroups(): void {
        this.categoryGroupService
            .getAll()
            .pipe(take(1), takeUntil(this.destroyed$))
            .subscribe((groups: Group[] | undefined): void => {
                if (!groups) {
                    this.userService.logout();
                }

                this.groups = groups;

                groups.forEach((group: Group): void => {
                    this.groupListMenuOptions.push({
                        key: `${group.id}`,
                        name: group.tag,
                        icon: {
                            type: 'radio_button_unchecked',
                            library: DynamoListMenuIconLibrary.MATERIAL,
                        },
                    });
                });
            });
    }

    fetchCategories(): void {
        this.categoryService
            .getAllByGroup(this.selectedGroup)
            .pipe(take(1), takeUntil(this.destroyed$))
            .subscribe((categories: Category[]): void => {
                this.categories = categories;

                this.updateCategoryListMenuOptions();
            });
    }

    onGroupChanged(groupId: string | undefined): void {
        this.clearCategories();

        const group: Group = this.getGroup(groupId);

        if (!group) {
            return;
        }

        this.selectedGroup = group;

        this.fetchCategories();

        this.groupChanged.emit(group);
    }

    onCategoryChanged(categoryId: string): void {
        // Check if the user clicked on the new category button in the DynamoListMenu, early-escape & execute actions.
        if (categoryId === 'new_category') {
            if (!this.commonService.IsPlatformBrowser()) {
                return;
            }

            const newCategoryName: string = prompt('Name der neuen Kategorie:');

            this.categoryService
                .save({
                    categoryGroup: { id: this.selectedGroup.id } as Group,
                    tag: newCategoryName,
                } as Category)
                .pipe(take(1), takeUntil(this.destroyed$))
                .subscribe((): void => {
                    this.categories = [];
                    this.fetchCategories();
                });

            return;
        }

        const category: Category | undefined = this.getCategory(categoryId);

        if (!category) {
            return;
        }

        this.categoryChanged.emit(category);
    }

    getGroup(groupId: string): Group | undefined {
        if (!this.groups) {
            return;
        }

        return this.groups.find((group: Group): boolean => group.id === parseInt(groupId, 10));
    }

    getCategory(categoryId: string): Category | undefined {
        if (!this.selectedGroup) {
            return;
        }

        return this.categories.find((category: Category): boolean => category.id === parseInt(categoryId, 10));
    }

    updateCategoryListMenuOptions(): void {
        if (!this.categories) {
            this.categoryListMenuOptions = undefined;
        }

        this.categoryListMenuOptions = [];

        this.categories?.forEach((category: Category): void => {
            this.categoryListMenuOptions.push({
                key: `${category.id}`,
                name: category.tag,
                icon: {
                    type: 'radio_button_unchecked',
                    library: DynamoListMenuIconLibrary.MATERIAL,
                },
            });
        });

        this.categoryListMenuOptions.push({
            key: 'new_category',
            name: 'Neue Kategorie',
            icon: {
                type: 'add_circle',
                library: DynamoListMenuIconLibrary.MATERIAL,
            },
        });
    }

    clearCategories(): void {
        this.categoryListMenuOptions = [];
        this.categories = undefined;
    }
}
